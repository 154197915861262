<template>
  <section id="content" ref="shopsContent">
    <PageTitle :title="$t('shop.admin.title')"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">

      <div class="row generate-success">
        <div class="col-lg-12">
          <ListShops></ListShops>
        </div>
      </div>

      <div class="row generate-success">
        <div class="col-lg-12">
          <ListShopContents></ListShopContents>
        </div>
      </div>

      <div class="row generate-success">
        <div class="col-lg-12">
          <ListCompanies></ListCompanies>
        </div>
      </div>

      <div class="row generate-success">
        <div class="col-lg-12">
          <ProviderCard></ProviderCard>
        </div>
      </div>

      <div class="row generate-success">
        <div class="col-lg-12">
          <ProductsWithoutDeliveryTypes></ProductsWithoutDeliveryTypes>
        </div>
      </div>

      <div class="row generate-success">
        <div class="col-lg-12">
          <CopyShop></CopyShop>
        </div>
      </div>

    </div>
  </section>
</template>

<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { useGenericPage } from '@fwk-client/composables/useGenericPage';

import PageTitle from '@root/src/client/components/panels/PageTitle.vue';

import ListShopContents from '../../../../vigneron-online/components/panels/admin/shop-content/ListShopContents.vue';

import ListShops from '../../panels/admin/shops/ListShops.vue';
import ListCompanies from '../../panels/admin/companies/ListCompanies.vue';
import ProviderCard from '../../panels/admin/ProviderCard.vue';
import ProductsWithoutDeliveryTypes from '../../panels/admin/ProductsWithoutDeliveryTypes.vue';
import CopyShop from '../../panels/admin/shops/Copy.vue';


export default defineComponent({
  props: {
  },
  components: {
    PageTitle,
    ListShops,
    ListCompanies,
    ProviderCard,
    CopyShop,
    ListShopContents,
    ProductsWithoutDeliveryTypes
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const genericPage = useGenericPage(props, context);

    return {

    }
  }
});
</script>