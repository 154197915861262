<template>
  
    <form role="form" @submit="onFormSubmit" name="create-shop">
        <div class="form-group">
            <label for="company" class="sr-only">{{$t('shop.info.company')}}</label>
            <div>
              <div v-if="listCompanies.length == 0" class="sk-spinner sk-spinner sk-spinner-three-bounce">
                  <div class="sk-bounce1"></div>
                  <div class="sk-bounce2"></div>
                  <div class="sk-bounce3"></div>
              </div>
              <v-select v-if="listCompanies.length > 0" id="company" :placeholder="$t('shop.info.company_placeholder')" v-model="shopForm.company" :options="listCompanies" label="name"></v-select>
            </div>
        </div>
        <TextField 
          ref="createName"
          v-bind:value.sync="shopForm.name" 
          id="createName" 
          :label="$t('shop.info.name')" 
          :placeholder="$t('shop.info.name_placeholder')" 
          :labelAsColumn="true"
          :required="true"
        />
        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">Create</button>
    </form>

</template>


<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import TextField from '@fwk-client/components/panels/input/TextField.vue';

import { useShopAdmin } from '../../../../../shop/composables/useShopAdmin';

export default defineComponent({
  props: {
      site: {
        type: Object,
        required: false
      }
  },
  components: {
      TextField
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();
    
    const { shopForm, createShop } = useShopAdmin(props, context);

    const listCompanies:Ref<any[]> = ref([]);

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var button:HTMLButtonElement|null = document.querySelector( 'form[name=create-shop] button' );
      laddaSubmit = Ladda.create(button!);
    })

    const updateCompanyList = (() => {
      // We need to get the list of available companies for the current logged in user
      var options:api.ApiVueOptions =  {
        app: app
      }
      api.getAPI('/api/admin/shop/companies/list', options).then((response:any) => {
        if(response.companies) {  
          listCompanies.value = response.companies;
        }
      });
    })
    updateCompanyList();

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();
      createShop().then((result:any) => {
        if(result.created) {  
          context.emit('shop-created', result.shop);
        }
        laddaSubmit!.stop();
      })
    }

    return {
      onFormSubmit,
      listCompanies,
      shopForm
    }
  }
})
</script>