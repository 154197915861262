<template>
  
    <form role="form" @submit="onFormSubmit" name="create-company">
        <div class="form-group">
            <label for="company" class="sr-only">{{$t('shop.info.company')}}</label>
            <div>
              <div v-if="listCompanies.length == 0" class="sk-spinner sk-spinner sk-spinner-three-bounce">
                  <div class="sk-bounce1"></div>
                  <div class="sk-bounce2"></div>
                  <div class="sk-bounce3"></div>
              </div>
              <v-select v-if="listCompanies.length > 0" id="company" :placeholder="$t('shop.info.company_placeholder')" v-model="form.company" :options="listCompanies" label="name"></v-select>
            </div>
        </div>
        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">Create</button>
    </form>

</template>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Prop, Component } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';
import * as Ladda from 'ladda';

@Component({
  components: { }
})
export default class Create extends Vue {

  form:any = {
    company: null
  };

  listCompanies:any[] = [];

  laddaSubmit:Ladda.LaddaButton|null = null;

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=create-company] button' );
    this.laddaSubmit = Ladda.create(button!);
  }

  created() {
    this.updateCompanyList();
  }

  updateCompanyList() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/admin/companies/list', options).then((response:any) => {
      if(response.companies) {  
        this.listCompanies = response.companies;
      }
    });
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "companyID" : this.form.company!._id
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/admin/shop/companies/create', input, options).then((response:any) => {
      if(response.created) {  
        this.$emit('company-created', response.company);

        // We reset the field
        this.form.company = null;
      }
      this.laddaSubmit!.stop();
    });
  }
  
}
</script>