<template>
  <div class="ibox methodsOfDelivery">
    <div class="ibox-title">
      <h2>{{ $t('shop.settings.methodsOfDelivery.products-without-delivery-types.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>

      <b-table 
          outlined striped
          :items="productsWithMissingTypes"
          :fields="listFields"
          show-empty
          :empty-text="$t('shop.settings.methodsOfDelivery.products-without-delivery-types.empty-list')"
          ref="listItems">
        <template v-slot:cell(select)="row">
          <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listItemsSelected"></p-check>
        </template>
        <template v-slot:cell(title)="row">
          {{row.item.title}}
          <span v-if="row.item.subtitle && row.item.subtitle!=''">
            <br/>{{row.item.subtitle}}
          </span>
        </template> 
        <template v-slot:cell(isActivated)="row">
          <i :class="['fa','fa-check',row.item.isActivated ? 'active' : 'disabled']"></i>
        </template> 
        <template v-slot:cell(description)="row">
          <div v-html="getLocalizedText(row.item.description)" ></div>
        </template>
        <template v-slot:cell(options)="row">
          <span v-if="isProductUpdateAllowed">
            <a href="javascript:void(0)" @click="showUpdateModal(row.item)">{{$t('shop.products.products.options.update')}}</a>
          </span>
        </template>
      </b-table>

      <b-modal size="xl" ref="updateItemModal" :title="$t('shop.products.products.update.title', {productID: itemToUpdate._id})" hide-footer lazy>
        <ProductForm :product="itemToUpdate" v-on:product-updated="onItemUpdated" />
      </b-modal>
    
    </div>
  </div>
</template>

<style scoped>
  .fa-check.active, .fa-user-circle-o.active {
    color:green;
  }
  .fa-check.disabled, .fa-user-circle-o.disabled {
    color:red;
  }

  
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import ProductForm from '../products/products/ProductForm.vue';

import { languagesTypes } from '@fwk-client/store/types';
import { formatDate } from '@igotweb-node-api-utils/formatter';

import { useProductAdmin } from '../../../composables/useProductAdmin';
import { useMethodsOfDeliveryAdmin } from '../../../composables/useMethodsOfDeliveryAdmin';


export default defineComponent({
  props: {
  },
  components: {
    ProductForm
  },
  setup(props, context) {
    const app = getApp();
    const router = useRouter();
    const store = useStore();

    const { isProductUpdateAllowed }  = useProductAdmin(props, context);
    const { getProductsWithMissingTypes }  = useMethodsOfDeliveryAdmin(props, context);

    const itemToUpdate:Ref<any> = ref({});

    const isProductsWithMissingTypesLoading:Ref<boolean> = ref(false);
    const listLoading:Ref<boolean> = computed(() => {
      return isProductsWithMissingTypesLoading.value;
    })

    const listItemsSelected:Ref<any[]> = ref([]);

    const listItems:Ref<HTMLElement|null> = ref(null);
    const updateItemModal:Ref<HTMLElement|null> = ref(null);

    const isMultiSelectAllowed:Ref<boolean> = ref(false);
    const showNumberOfRows:Ref<boolean> = ref(false);

    const productsWithMissingTypes:Ref<any[]> = ref([]);

    const numberOfRows:Ref<number> = computed(() => {
        return productsWithMissingTypes.value.length
    })

    const methodOfDeliveryTypeFormatter = () => {
      return (value:any, key:any, item:any) => {
        var result = "";
        for(var key of value) {
          if(result != "") { result += ", "; }
          result += app.$t('shop.orders.methodsOfDelivery.'+key) as string;
        }
        return result;
      }
    }

    const listFields = [
      {
        key: "select",
        label: "",
        class: (isMultiSelectAllowed.value ? "" : "hidden"),
      },
      {
        key: "shopName",
        label: "",
      },
      {
        key: "title",
        label: "",
      },
      {
        key: "isActivated",
        label: ""
      },
      {
        key: "missingTypes",
        label: "",
        formatter: methodOfDeliveryTypeFormatter()
      },
      {
        key: "options"
      }
    ];

    onMounted(() => {
      listFields[1].label = app.$t('shop.products.products.headers.shopName') as string;
      listFields[2].label = app.$t('shop.products.products.headers.title') as string;
      listFields[3].label = app.$t('shop.products.products.headers.isActivated') as string;
      listFields[4].label = app.$t('shop.products.products.headers.missingDeliveryTypes') as string;
      listFields[5].label = app.$t('shop.products.products.headers.options') as string;
    })

    const updateProductsWithMissingTypes = () => {
      isProductsWithMissingTypesLoading.value = true;
      getProductsWithMissingTypes().then((products) => {
        isProductsWithMissingTypesLoading.value = false;
        productsWithMissingTypes.value = products;
      })
    }
    updateProductsWithMissingTypes();

    const onItemUpdated = (item:any) => {
      itemToUpdate.value = item;
    }

    const showUpdateModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      updateItemModal.value.show()
    }

    return {
      listLoading,
      productsWithMissingTypes,
      listFields,
      listItems,
      listItemsSelected,
      isProductUpdateAllowed,
      showNumberOfRows,
      numberOfRows,

      showUpdateModal,
      updateItemModal,
      itemToUpdate,
      onItemUpdated
    }
  }
})
</script>