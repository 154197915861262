<template>
  <div>
    <form role="form" @submit="onFormSubmit" name="update-shop">
      <div class="row">
        <div class="col-lg-12">
          <h3>{{$t('shop.update.info')}}</h3>
        </div>
      </div>
      <TextField 
          ref="updateName"
          v-bind:value.sync="shopForm.name" 
          id="updateName" 
          :label="$t('shop.info.name')" 
          :placeholder="$t('shop.info.name_placeholder')" 
          :labelAsColumn="true"
          :required="true"
      />
      <TextField 
          ref="updateOrderNotificationEMail"
          v-bind:value.sync="shopForm.orderNotificationEMail" 
          id="updateOrderNotificationEMail" 
          :label="$t('shop.info.orderNotificationEMail')" 
          :placeholder="$t('shop.info.orderNotificationEMail_placeholder')" 
          :labelAsColumn="true"
          :required="true"
      />
      <div class="form-group row">
        <label for="updateIsPromoCodesAllowed" class="col-lg-3 col-form-label">{{$t('shop.info.isPromoCodesAllowed')}}</label>
        <div class="col-lg-9">
          <p-check id="updateIsPromoCodesAllowed" class="p-switch p-fill" color="success" v-model="shopForm.isPromoCodesAllowed">&nbsp;</p-check>
        </div>
      </div>
      <div class="form-group row">
        <label for="updateIsActivated" class="col-lg-3 col-form-label">{{$t('shop.info.isActivated')}}</label>
        <div class="col-lg-9">
          <p-check id="updateIsActivated" class="p-switch p-fill" color="success" v-model="shopForm.isActivated">&nbsp;</p-check>
        </div>
      </div>
      <Select 
        :selectOptions="{
          options: listProductTypes,
          getOptionLabel : option => $t('shop.products.products.types.'+option),
          multiple: true
        }"
        v-bind:value.sync="shopForm.allowedProductTypes" 
        id="updateAllowedProductTypes_select" 
        :label="$t('shop.info.allowedProductTypes')" 
        :placeholder="$t('shop.info.allowedProductTypes_placeholder')"  
        labelAsColumn 
      />
      <Select 
        :selectOptions="{
          options: listActivityTypes,
          getOptionLabel : option => $t('shop.products.activities.types.'+option),
          multiple: true
        }"
        v-bind:value.sync="shopForm.allowedActivityTypes" 
        id="updateAllowedActivityTypes_select" 
        :label="$t('shop.info.allowedActivityTypes')" 
        :placeholder="$t('shop.info.allowedActivityTypes_placeholder')"  
        labelAsColumn 
      />
      <button class="btn btn-primary ladda-button update" data-style="zoom-in" type="submit">{{$t('shop.update.button')}}</button>
    </form>

  </div>

</template>

<style>

</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import LocalizedText from '@fwk-client/components/panels/input/LocalizedText.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';

import { useShopAdmin } from '../../../../../shop/composables/useShopAdmin';

export default defineComponent({
  props: {
      shop: {
        type: Object,
        required: true
      }
  },
  components: {
      TextField, LocalizedText, Select
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();
    
    const { shops, updateShop, updateShopFormForUpdate, shopForm } = useShopAdmin(props, context);

    const { shop } = toRefs(props);

    const listProductTypes = ['PRODUCT', 'WINE']

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-shop] button.update' );
      laddaSubmit = Ladda.create(submitButton!);
    })

    updateShopFormForUpdate(shop.value);

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();
      updateShop().then((result:any) => {
        if(result.updated) {
          context.emit('shop-updated', result.shop);
        }
        laddaSubmit!.stop();
      })
    }

    // We watch if we have site ID in URL
    watch(
      shop,
      (val:any, oldVal:any) => {
        updateShopFormForUpdate(shop.value);
      },
      { deep: true }
    )

    return {
      onFormSubmit,
      shopForm,
      listProductTypes
    }
  }
})
</script>