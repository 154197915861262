<template>
  
  <div class="ibox">
    <div class="ibox-title">
      <h2>Copy Shop</h2>
    </div>
    <div :class="'ibox-content p-md'+ (panelLoading ? ' sk-loading' : '')">

      <div v-if="panelLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <form role="form" @submit="onFormSubmit" name="copyShopForm">
        <div class="form-group row">
          <div class="col">
            <v-select :options="listDBs" :getOptionLabel="listDBsOptionLabel" v-model="form.originDB" placeholder="Select an origin DB" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.originDB"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
          <div class="col">
            <v-select :options="listShops" v-model="form.shop" :getOptionLabel="option => option.shop.name" placeholder="Select a shop" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.shop"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <v-select :options="listDBs" :getOptionLabel="listDBsOptionLabel" v-model="form.destinationDB" placeholder="Select a destination DB" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.destinationDB"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
          <div class="col">
            <SwitchCheck 
                v-bind:value.sync="form.keepMethodsOfPayment" 
                id="copyKeepMethodsOfPayment" 
                label="Keep methods of payment"
                :labelAsColumn="true"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">Copy</button>
          </div>
        </div>
      </form>

      

    </div>
  </div>
        
</template>

<style scoped>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';

@Component({
  components: {
    SwitchCheck
  }
})
export default class Copy extends Vue {

  listDBs:any[] = [];

  listPathPrefix:any[] = [
    "dev-staging",
    "dev-production",
    "staging",
    "production"
  ];

  listShops:any[] = [];

  form:any = {
    shop:"",
    originDB:"",
    destinationDB:"",
    keepMethodsOfPayment:true
  }

  panelLoading:boolean = false;

  created() {
    this.updateListDBs();
  }

  onFormSubmit(evt:Event) {
    evt.preventDefault();

    var options:api.ApiVueOptions =  {
      app: this
    }

    var input = {
      shopID : this.form.shop.shop._id,
      originDB : this.form.originDB.title,
      destinationDB : this.form.destinationDB.title,
      keepMethodsOfPayment : this.form.keepMethodsOfPayment
    }

    this.panelLoading = true;

    return api.postAPI('/api/admin/shop/shops/copy', input,  options).then((response:any) => {
      this.panelLoading = false;
    })
  }

  updateListShops() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }

    var input = {
      targetDB : this.form.originDB.title
    }

    return api.postAPI('/api/admin/shop/shops/list', input, options).then((response:any) => {
      if(response.shops) {  
        this.listShops = response.shops;
      }
      else {
        this.listShops = [];
      }
    });
  }

  updateListDBs() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    this.panelLoading = true;
    api.getAPI('/api/admin/database/configs', options).then((response:any) => {
      if(response.configs) {  
        this.listDBs = response.configs;
      }
      else {
        this.listDBs = [];
      }
      this.panelLoading = false;
    });
  }

  listDBsOptionLabel(option:any) {
    return option.title;
  }

  @Watch('form.originDB')
  onValueChange(val: any, oldVal: any) {
    if(val == null) {
      this.listShops = [];
    }
    else {
      this.updateListShops();
    }
  }
  
}
</script>