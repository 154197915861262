<template>
  <div class="ibox shop-contents">
    <div class="ibox-title">
      <h2>{{ $t('vigneron-online.shop-content.list.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>
    
      <b-table 
          outlined striped
          :items="shopContents"
          :fields="listFields"
          ref="listItems">
        <template v-slot:cell(select)="row">
          <p-check class="p-default p-curve p-primary" color="success" :value="row.item.shopContent._id" v-model="listItemsSelected"></p-check>
        </template>
        <template v-slot:cell(web)="row">
          <a v-if="row.item.web" :href="row.item.shopContent.web" target="_blank">{{row.item.shopContent.web}}</a>
        </template>
        <template v-slot:cell(options)="row">
          <span v-if="isShopContentUpdateAllowed">
            <a href="javascript:void(0)" @click="showUpdateModal(row.item)">{{$t('vigneron-online.shop-content.list.options.update')}}</a>
            /
          </span>
          <span v-if="isShopContentRemoveAllowed">
            <a href="javascript:void(0)" @click="confirmRemoveItem(row.item)">{{$t('vigneron-online.shop-content.list.options.delete')}}</a>
            /
          </span>
          <a href="javascript:void(0)" @click="showShopThemePage(row.item)">{{$t('vigneron-online.shop-content.list.options.theme')}}</a>
          /
          <a href="javascript:void(0)" @click="showDeploymentPage(row.item)">{{$t('vigneron-online.shop-content.list.options.deployment')}}</a>
        </template>
      </b-table>

      <button v-if="isShopContentCreateAllowed" class="btn btn-primary" @click="showCreateItemModal()">{{$t('vigneron-online.shop-content.list.create-button')}}</button>

      <b-modal size="lg" ref="updateItemModal" :title="$t('vigneron-online.shop-content.update.title', {shopName: itemToUpdate.shop.name})" hide-footer lazy>
        <ShopContentForm :shopContent="itemToUpdate" v-on:shop-content-updated="onItemUpdated" />
      </b-modal>

      <b-modal size="lg" ref="createItemModal" :title="$t('vigneron-online.shop-content.create.title')" hide-footer lazy>
        <ShopContentForm v-on:shop-created="onItemCreated" />
      </b-modal>

      <b-modal ref="removeItemModal" 
          hide-header
          @ok="removeItem">
        {{$t('vigneron-online.shop-content.list.delete-confirmation', {shopName: itemToRemove.shop.name})}}
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import ShopContentForm from './ShopContentForm.vue';
import * as api from '@fwk-client/utils/api';
import { languagesTypes } from '@fwk-client/store/types';

import { useVigneronOnlineAdmin } from '../../../../composables/useVigneronOnlineAdmin';

@Component({
  components: { 
    ShopContentForm
  }
})
export default class ListShopContents extends Vue {

  setup(props:any, context:any) {
    const { shopContents, isShopContentCreateAllowed, isShopContentRemoveAllowed, isShopContentUpdateAllowed } = useVigneronOnlineAdmin(props, context);
    return { shopContents, isShopContentCreateAllowed, isShopContentRemoveAllowed, isShopContentUpdateAllowed }
  }

  isMultiSelectAllowed = false;
  showNumberOfRows = false;

  numberOfRows:number = 0;
  productionShopContents:any[] = [];

  itemToRemove:any = {
    "shop" : {
      "name" : ""
    }
  };
  itemToUpdate:any = {
    "shop" : {
      "name" : ""
    }
  };

  listLoading:boolean = false;
  listItemsSelected = [];


  listFields = [
      {
        key: "select",
        label: "",
        class: (this.isMultiSelectAllowed ? "" : "hidden"),
      },
      {
        key: "shopContent.shop.name",
        label: ""
      },
      {
        key: "shopContent.path",
        label: ""
      },
      {
        key: "options"
      }
    ];

  mounted() {
    this.listFields[1].label = this.$t('vigneron-online.shop-content.list.headers.shop-name') as string;
    this.listFields[2].label = this.$t('vigneron-online.shop-content.list.headers.path') as string;
    this.listFields[3].label = this.$t('vigneron-online.shop-content.list.headers.options') as string;
  }

  get currentLanguageCode() {
    return this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
  }

  onItemRemoved() {
    // @ts-ignore
    this.$refs.listItems.refresh()
  }

  onItemCreated() {
    // @ts-ignore
    this.$refs.createItemModal.hide()
    // @ts-ignore
    this.$refs.listItems.refresh()
  }

  onItemUpdated(item:any) {
    this.itemToUpdate = item;
    // @ts-ignore
    this.$refs.listItems.refresh()
  }
  
  confirmRemoveItem(item:any) {
    this.itemToRemove = item;
    // @ts-ignore
    this.$refs.removeItemModal.show()
  }

  showUpdateModal(item:any) {
    this.itemToUpdate = item;
    // @ts-ignore
    this.$refs.updateItemModal.show()
  }

  showCreateItemModal() {
    // @ts-ignore
    this.$refs.createItemModal.show()
  }

  showShopThemePage(shopContent:any) {
    this.$router.push("/"+this.currentLanguageCode+"/vigneron-online/theme/"+shopContent._id).catch(err => {});
  }

  showDeploymentPage(shopContent:any) {
    this.$router.push("/"+this.currentLanguageCode+"/vigneron-online/deployment/"+shopContent._id).catch(err => {});
  }

  removeItem() {

    var input = {}

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.listLoading = true;
    
    api.postAPI('/api/admin/vigneron-online/shop-content/'+this.itemToRemove._id+'/remove', input, options).then((response:any) => {
      if(response.removed) {
        // We update the list of shops
        this.onItemRemoved();
      }
      // We reset the user to remove
      this.itemToRemove = {};

      this.listLoading = false;
    });
  }
}
</script>