<template>
  <div class="ibox methodsOfPayment">
    <div class="ibox-title">
      <h2>{{ $t('shop.settings.methodsOfPayment.card.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <h3>Staging</h3>
      <div class="row">
        <div class="col-lg-6">
          <h4>Test</h4>
          <form role="form" @submit="onTestProviderCardFormSubmit" name="update-test-provider-card">
            <div class="form-group row">
              <label for="updateTestClientID" class="col-lg-3 col-form-label">Client ID</label>
              <div class="col-lg-9">
                <input id="updateTestClientID" type="text" placeholder="" class="form-control" v-model="form.test.clientID" />
              </div>
            </div>
            <div class="form-group row">
              <label for="updateTestPublishableKey" class="col-lg-3 col-form-label">publishableKey</label>
              <div class="col-lg-9">
                <input id="updateTestPublishableKey" type="text" placeholder="" class="form-control" v-model="form.test.publishableKey" />
              </div>
            </div>
            <div class="form-group row">
              <label for="updateTestSecretKey" class="col-lg-3 col-form-label">secretKey</label>
              <div class="col-lg-9">
                <input id="updateTestSecretKey" type="text" placeholder="" class="form-control" v-model="form.test.secretKey" />
              </div>
            </div>
            <button class="btn btn-primary ladda-button update-test" data-style="zoom-in" type="submit">{{$t('shop.update.button')}}</button>
            <button class="btn btn-primary ladda-button publish-test" data-style="zoom-in" type="button" @click="onTestProviderCardPublish">Publish</button>
          </form>
        </div>
        <div class="col-lg-6">
          <h4>Live</h4>
          <form role="form" @submit="onLiveProviderCardFormSubmit" name="update-live-provider-card">
            <div class="form-group row">
              <label for="updateLiveClientID" class="col-lg-3 col-form-label">Client ID</label>
              <div class="col-lg-9">
                <input id="updateLiveClientID" type="text" placeholder="" class="form-control" v-model="form.live.clientID" />
              </div>
            </div>
            <div class="form-group row">
              <label for="updateLivePublishableKey" class="col-lg-3 col-form-label">publishableKey</label>
              <div class="col-lg-9">
                <input id="updateLivePublishableKey" type="text" placeholder="" class="form-control" v-model="form.live.publishableKey" />
              </div>
            </div>
            <div class="form-group row">
              <label for="updateLiveSecretKey" class="col-lg-3 col-form-label">secretKey</label>
              <div class="col-lg-9">
                <input id="updateLiveSecretKey" type="text" placeholder="" class="form-control" v-model="form.live.secretKey" />
              </div>
            </div>
            <button class="btn btn-primary ladda-button update-live" data-style="zoom-in" type="submit">{{$t('shop.update.button')}}</button>
            <button class="btn btn-primary ladda-button publish-live" data-style="zoom-in" type="button" @click="onLiveProviderCardPublish">Publish</button>
          </form>
        </div>
      </div>

      <h3>Production</h3>
      <div class="row">
        <div class="col-lg-6">
          <h4>Test</h4>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">Client ID</label>
            <div class="col-lg-9">
              {{ production.test.clientID }}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">publishableKey</label>
            <div class="col-lg-9">
              {{ production.test.publishableKey }}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">secretKey</label>
            <div class="col-lg-9">
              {{production.test.secretKey }}
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <h4>Live</h4>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">Client ID</label>
            <div class="col-lg-9">
              {{production.live.clientID }}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">publishableKey</label>
            <div class="col-lg-9">
              {{production.live.publishableKey }}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label">secretKey</label>
            <div class="col-lg-9">
              {{production.live.secretKey }}
            </div>
          </div>
        </div>
      </div>
    
    </div>
  </div>
</template>

<style>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import { mixins } from '@fwk-node-modules/vue-class-component';
import * as api from '@fwk-client/utils/api';
import { languagesTypes } from '@fwk-client/store/types';

@Component({
  components: { 
  }
})
export default class ProviderCard extends Vue {

  listLoading:boolean = false;

  form:any = {
    test : {
      clientID : "",
      publishableKey : "",
      secretKey : "",
      backend: ""
    },
    live : {
      clientID : "",
      publishableKey : "",
      secretKey : "",
      backend: ""
    }
  }

  production:any = {
    test : {
      clientID : "",
      publishableKey : "",
      secretKey : "",
      backend: ""
    },
    live : {
      clientID : "",
      publishableKey : "",
      secretKey : "",
      backend: ""
    }
  }

  created() {
    this.updateStagingProviderCard();
    this.updateProductionProviderCard();
  }

  mounted() {
    
  }

  updateStagingProviderCard() {
    this.getCard(this.form, 'staging-provider-cards');
  }

  updateProductionProviderCard() {
    this.getCard(this.production, 'production-provider-cards');
  }

  getCard(form:any, path:string) {
    var options:api.ApiVueOptions =  {
      app: this
    }

    this.listLoading = true;

    return api.getAPI('/api/admin/shop/payment/'+path, options).then((response:any) => {
      // We check the additional fields
      if(response.test) {
        form.test = response.test.configuration;
      }
      if(response.live) {
        form.live = response.live.configuration;
      }
      
      this.listLoading = false;

    })
  }

  onTestProviderCardFormSubmit(evt:Event) {
    evt.preventDefault();
    return this.updateCard('test');
  }

  onLiveProviderCardFormSubmit(evt:Event) {
    evt.preventDefault();
    return this.updateCard('live');
  }

  updateCard(type:string) {
    var options:api.ApiVueOptions =  {
      app: this
    }

    var input = {
      ...this.form[type]
    }

    this.listLoading = true;

    return api.postAPI('/api/admin/shop/payment/'+type+'-provider-card/update', input, options).then((response:any) => {
      // We check the additional fields
      if(response.card) {
        this.form[type] = response.card.configuration;
      }
      
      this.listLoading = false;

    })
  }

  onTestProviderCardPublish() {
    this.publishCard('test');
  }

  onLiveProviderCardPublish() {
    this.publishCard('live');
  }

  publishCard(type:string) {
    var options:api.ApiVueOptions =  {
      app: this
    }

    var input = {}

    this.listLoading = true;

    return api.postAPI('/api/admin/shop/payment/'+type+'-provider-card/publish', input, options).then((response:any) => {
      // We check the additional fields
      if(response.card) {
        this.production[type] = response.card.configuration;
      }
      
      this.listLoading = false;

    })
  }
}
</script>