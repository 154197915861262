<template>
  <div class="ibox companies">
    <div class="ibox-title">
      <h2>{{ $t('shop.admin.companies.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>
    
      <b-table 
          outlined striped
          :items="listProvider"
          :fields="listFields"
          ref="listItems">
        <template v-slot:cell(select)="row">
          <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listItemsSelected"></p-check>
        </template>
        <template v-slot:cell(web)="row">
          <a v-if="row.item.web" :href="row.item.web" target="_blank">{{row.item.web}}</a>
        </template>
        <template v-slot:cell(options)="row">
          <span v-if="isUpdateAllowed">
            <a href="javascript:void(0)" @click="confirmSynchronizeItem(row.item)">{{$t('shop.admin.companies.options.synchronize')}}</a>
          </span>
          <span v-if="isRemoveAllowed">
            /
            <a href="javascript:void(0)" @click="confirmRemoveItem(row.item)">{{$t('shop.admin.companies.options.delete')}}</a>
          </span>
        </template>
      </b-table>

      <button v-if="isCreateAllowed" class="btn btn-primary" @click="showCreateItemModal()">{{$t('shop.admin.companies.create-button')}}</button>

      <b-modal size="lg" ref="createItemModal" title="Publish company" hide-footer lazy>
        <CreateCompany v-on:company-created="onItemCreated" />
      </b-modal>

      <b-modal ref="removeItemModal" 
          hide-header
          @ok="removeItem">
        {{$t('shop.admin.companies.delete-confirmation', {companyName: itemToRemove.name})}}
      </b-modal>

      <b-modal ref="synchronizeItemModal" 
          hide-header
          @ok="synchronizeItem">
        {{$t('shop.admin.companies.synchronize-confirmation', {companyName: itemToUpdate.name})}}
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import { mixins } from '@fwk-node-modules/vue-class-component';
import CreateCompany from './Create.vue';
import * as api from '@fwk-client/utils/api';
import { languagesTypes } from '@fwk-client/store/types';

@Component({
  components: { 
    CreateCompany
  }
})
export default class ListCompanies extends Vue {

  isCreateAllowed = false;
  isUpdateAllowed = false;
  isRemoveAllowed = false;
  isMultiSelectAllowed = false;
  showNumberOfRows = false;

  numberOfRows:number = 0;

  itemToRemove:any = {
    "company" : {
      "name" : ""
    }
  };
  itemToUpdate:any = {
    "company" : {
      "name" : ""
    }
  };

  listLoading:boolean = false;
  listItemsSelected = [];

  listFields = [
      {
        key: "select",
        label: "",
        class: (this.isMultiSelectAllowed ? "" : "hidden"),
      },
      {
        key: "name",
        label: ""
      },
      {
        key: "web",
        label: ""
      },
      {
        key: "options"
      }
    ];

  mounted() {
    this.listFields[1].label = this.$t('shop.admin.companies.headers.company') as string;
    this.listFields[2].label = this.$t('shop.admin.companies.headers.web') as string;
    this.listFields[3].label = this.$t('shop.admin.companies.headers.options') as string;
  }

  get currentLanguageCode() {
    return this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
  }

  onItemRemoved() {
    // @ts-ignore
    this.$refs.listItems.refresh()
  }

  onItemUpdated() {
    // @ts-ignore
    this.$refs.listItems.refresh()
  }

  onItemCreated() {
    // @ts-ignore
    this.$refs.createItemModal.hide()
    // @ts-ignore
    this.$refs.listItems.refresh()
  }
  
  confirmRemoveItem(item:any) {
    this.itemToRemove = item;
    // @ts-ignore
    this.$refs.removeItemModal.show()
  }

  confirmSynchronizeItem(item:any) {
    this.itemToUpdate = item;
    // @ts-ignore
    this.$refs.synchronizeItemModal.show()
  }

  synchronizeItem(item:any) {
    var input = {
      "companyID" : this.itemToUpdate._id
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.listLoading = true;
    
    api.postAPI('/api/admin/shop/companies/synchronize', input, options).then((response:any) => {
      if(response.updated) {
        // We update the list of shops
        this.onItemUpdated();
      }
      // We reset the user to remove
      this.itemToUpdate = {};

      this.listLoading = false;
    });
  }

  showCreateItemModal() {
    // @ts-ignore
    this.$refs.createItemModal.show()
  }

  listProvider(ctx:any) {
    var options:api.ApiVueOptions =  {
      app: this
    }

    this.listLoading = true;

    return api.getAPI('/api/admin/shop/companies/list', options).then((response:any) => {
      // We check the additional fields
      if(response.fields) {
        this.listFields.splice(2, 0, ...(response.fields.map((field:string) => {
          return {
            key: field,
            label: this.$t('shop.admin.companies.headers.' + field) as string
          }
        })));
      }
      if(response.options) {
        this.isCreateAllowed = response.options.isCreateAllowed;
        this.isUpdateAllowed = response.options.isUpdateAllowed;
        this.isRemoveAllowed = response.options.isRemoveAllowed;
        this.isMultiSelectAllowed = response.options.isMultiSelectAllowed;
        this.showNumberOfRows = response.options.showNumberOfRows;
      }

      this.listLoading = false;

      if(response.companies) {  
        if(this.showNumberOfRows) {
          this.numberOfRows = response.companies.length;
        }
        return response.companies;
      }
      else {
        return [];
      }
    })
  }

  removeItem() {

    var input = {
      "companyID" : this.itemToRemove._id
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.listLoading = true;
    
    api.postAPI('/api/admin/shop/companies/remove', input, options).then((response:any) => {
      if(response.removed) {
        // We update the list of shops
        this.onItemRemoved();
      }
      // We reset the user to remove
      this.itemToRemove = {};

      this.listLoading = false;
    });
  }
}
</script>