<template>
  <div class="ibox shops">
    <div class="ibox-title">
      <h2>{{ $t('shop.list.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>
    
      <b-table 
          outlined striped
          :items="shops"
          :fields="listFields"
          ref="listItems">
        <template v-slot:cell(select)="row">
          <p-check class="p-default p-curve p-primary" color="success" :value="row.item.shop._id" v-model="listItemsSelected"></p-check>
        </template>
        <template v-slot:cell(web)="row">
          <a v-if="row.item.shop.web" :href="row.item.shop.web" target="_blank">{{row.item.shop.web}}</a>
        </template>
        <template v-slot:cell(options)="row">
          <span v-if="isShopUpdateAllowed">
            <a href="javascript:void(0)" @click="siteForm(row.item.shop)">{{$t('shop.list.options.update')}}</a>
            /
          </span>
          <span v-if="isShopRemoveAllowed">
            <a href="javascript:void(0)" @click="confirmRemoveItem(row.item.shop)">{{$t('shop.list.options.delete')}}</a>
            /
          </span>
          <a href="javascript:void(0)" @click="showShopProductsPage(row.item.shop)">{{$t('shop.list.options.products')}}</a>
          /
          <a href="javascript:void(0)" @click="showShopStockPage(row.item.shop)">{{$t('shop.list.options.stock')}}</a>
          /
          <a href="javascript:void(0)" @click="showShopPromoCodesPage(row.item.shop)">{{$t('shop.list.options.promoCodes')}}</a>
          /
          <a href="javascript:void(0)" @click="showShopOrdersPage(row.item.shop)">{{$t('shop.list.options.orders')}}</a>
          /
          <a href="javascript:void(0)" @click="showShopSettingsPage(row.item.shop)">{{$t('shop.list.options.settings')}}</a>
          /
          <a href="javascript:void(0)" @click="showDeploymentPage(row.item.shop)">{{$t('shop.list.options.deployment')}}</a>
          /
          <a href="javascript:void(0)" @click="showShopPayoutsPage(row.item.shop)">{{$t('shop.list.options.payouts')}}</a>
        </template>
      </b-table>

      <button v-if="isShopCreateAllowed" class="btn btn-primary" @click="showCreateItemModal()">{{$t('shop.list.create-button')}}</button>

      <b-modal size="lg" ref="updateItemModal" :title="$t('shop.update.title', {shopName: itemToUpdate.name})" hide-footer lazy>
        <UpdateShop :shop="itemToUpdate" v-on:shop-updated="onItemUpdated" />
      </b-modal>

      <b-modal size="lg" ref="createItemModal" title="Create new shop" hide-footer lazy>
        <CreateShop v-on:shop-created="onItemCreated" />
      </b-modal>

      <b-modal ref="removeItemModal" 
          hide-header
          @ok="removeItem">
        {{$t('shop.list.delete-confirmation', {shopName: itemToRemove.name})}}
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  
</style>



<script lang="ts">
import { Ref, defineComponent, ComputedRef, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import UpdateShop from './Update.vue';
import CreateShop from './Create.vue';

import { languagesTypes } from '@fwk-client/store/types';

import { useShopAdmin } from '../../../../composables/useShopAdmin';


export default defineComponent({
  props: {
  },
  components: {
    UpdateShop, CreateShop
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { shops, selectShop, isShopUpdateAllowed, isShopCreateAllowed, isShopRemoveAllowed, isListLoading, removeShop } = useShopAdmin(props, context);

    const listItems:Ref<HTMLElement|null> = ref(null);
    const createItemModal:Ref<HTMLElement|null> = ref(null);
    const removeItemModal:Ref<HTMLElement|null> = ref(null);
    const updateItemModal:Ref<HTMLElement|null> = ref(null);

    const isMultiSelectAllowed:Ref<boolean> = ref(false);
    const showNumberOfRows:Ref<boolean> = ref(false);

    const numberOfRows:Ref<number> = computed(() => {
        return shops.value.length
    })

    const itemToRemove:Ref<any> = ref({
      "company" : {
        "name" : ""
      }
    });
    const itemToUpdate:Ref<any> = ref({
      "company" : {
        "name" : ""
      }
    });

    
    const listLoading:Ref<boolean> = computed(() => {
      return isListLoading.value || removeLoading.value;
    })

    const listItemsSelected:Ref<any[]> = ref([]);

    const listFields = [
      {
        key: "select",
        label: "",
        class: (isMultiSelectAllowed.value ? "" : "hidden"),
      },
      {
        key: "shop.name",
        label: ""
      },
      {
        key: "shop.company.name",
        label: ""
      },
      {
        key: "options"
      }
    ];

    onMounted(() => {
      listFields[1].label = app.$t('shop.list.headers.name') as string;
      listFields[2].label = app.$t('shop.list.headers.company') as string;
      listFields[3].label = app.$t('shop.list.headers.options') as string;
    })

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const onItemCreated = () => {
      // @ts-ignore
      createItemModal.value.hide()
    }

    const onItemUpdated = (item:any) => {
      itemToUpdate.value = item;
    }

    const confirmRemoveItem = (item:any) => {
      itemToRemove.value = item;
      // @ts-ignore
      removeItemModal.value.show()
    }

    const siteForm = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      updateItemModal.value.show()
    }

    const showCreateItemModal = () => {
      // @ts-ignore
      createItemModal.value.show()
    }

    const showShopOrdersPage = (shop:any) => {
      // We first should select the shop
      selectShop(shop._id).then(() => {
        // We redirect to the shop dedicated page
        $router.push("/"+currentLanguageCode.value+"/shop/orders").catch((err:any) => {});
      })
    }

    const showShopProductsPage= (shop:any) => {
      // We first should select the shop
      selectShop(shop._id).then(() => {
        // We redirect to the shop dedicated page
        $router.push("/"+currentLanguageCode.value+"/shop/products").catch((err:any) => {});
      })
    }

    const showShopStockPage = (shop:any) => {
      // We first should select the shop
      selectShop(shop._id).then(() => {
        // We redirect to the shop dedicated page
        $router.push("/"+currentLanguageCode.value+"/shop/stock").catch((err:any) => {});
      })
    }

    const showShopPromoCodesPage = (shop:any) => {
      // We first should select the shop
      selectShop(shop._id).then(() => {
        // We redirect to the shop dedicated page
        $router.push("/"+currentLanguageCode.value+"/shop/promoCodes").catch((err:any) => {});
      })
    }

    const showShopSettingsPage = (shop:any) => {
      // We first should select the shop
      selectShop(shop._id).then(() => {
        // We redirect to the shop dedicated page
        $router.push("/"+currentLanguageCode.value+"/shop/settings").catch((err:any) => {});
      })
    }

    const showDeploymentPage = (shop:any) => {
      // We first should select the shop
      selectShop(shop._id).then(() => {
        // We redirect to the shop dedicated page
        $router.push("/"+currentLanguageCode.value+"/shop/deployment").catch((err:any) => {});
      })
    }

    const showShopPayoutsPage = (shop:any) => {
      // We first should select the shop
      selectShop(shop._id).then(() => {
        // We redirect to the shop dedicated page
        $router.push("/"+currentLanguageCode.value+"/shop/payouts").catch((err:any) => {});
      })
    }

    const removeLoading:Ref<boolean> = ref(false);
    const removeItem = () => {

      removeLoading.value = true;
      removeShop(itemToRemove.value._id).then((removed:boolean) => {
        // We reset the user to remove
        itemToRemove.value = {};

        removeLoading.value = false;
      })
    }
    return {
      listLoading,
      showNumberOfRows,
      numberOfRows,
      shops,
      listItems,
      listFields,
      listItemsSelected,
      isShopUpdateAllowed,
      siteForm,
      isShopRemoveAllowed,
      confirmRemoveItem,
      showShopPayoutsPage,
      showShopSettingsPage,
      showShopPromoCodesPage,
      showDeploymentPage,
      showShopStockPage,
      showShopProductsPage,
      showShopOrdersPage,
      isShopCreateAllowed,
      showCreateItemModal,
      updateItemModal,
      itemToUpdate,
      onItemUpdated,
      createItemModal,
      onItemCreated,
      removeItemModal,
      removeItem,
      itemToRemove
    }
  }
})
</script>