<template>
  
  <form role="form" @submit="onFormSubmit" name="shop-content-form">
    <div class="form-group row">
          <label for="shop" class="col-lg-3 col-form-label">{{ $t('vigneron-online.shop-content.info.shop') }}</label>
          <div class="col-lg-9">
            <div v-if="listShops.length == 0" class="sk-spinner sk-spinner sk-spinner-three-bounce">
                <div class="sk-bounce1"></div>
                <div class="sk-bounce2"></div>
                <div class="sk-bounce3"></div>
            </div>
            <v-select v-if="listShops.length > 0" id="shop" :placeholder="$t('vigneron-online.shop-content.info.shop_placeholder')" v-model="form.shop" :options="listShops" label="name"></v-select>
          </div>
      </div>
      <TextField 
        ref="createPath"
        v-bind:value.sync="form.path" 
        id="createPath" 
        :label="$t('vigneron-online.shop-content.info.path')" 
        :placeholder="$t('vigneron-online.shop-content.info.path_placeholder')" 
        :labelAsColumn="true"
        :required="false"
      />
      <Select
        v-if="listFeeds.length > 0"
        :label="$t('cms.site.info.feeds')"
        :placeholder="$t('cms.site.info.feeds_placeholder')"
        :selectOptions="{
          multiple: true,
          options: availableFeeds,
          getOptionLabel : option => option.name
        }"
        v-bind:value.sync="form.feeds" 
        :labelAsColumn="true"
      />
      <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{buttonLabel}}</button>
    </form>

</template>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

@Component({
  components: { TextField, Select }
})
export default class ShopContentForm extends Vue {

  @Prop({
    type: Object,
    required: false
  }) readonly shopContent!: any | undefined

  @Prop({
    type: Object,
    required: false
  }) readonly productionShopContent!: any | undefined

  form:any = {};

  listShops:any[] = [];
  listFeeds:any[] = [];

  laddaSubmit:Ladda.LaddaButton|null = null;

  get availableFeeds() {
    return this.listFeeds.filter((feed:any) => {
      if(!this.form.feeds) { return true; }
      else {
        return this.form.feeds.map((formFeed:any) => formFeed._id).indexOf(feed._id) < 0
      }
    })
  }

  get buttonLabel() {
    if(this.shopContent) { return this.$t('shop.update.button') }
    else { return this.$t('vigneron-online.shop-content.create.button'); }
  }

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=shop-content-form] button' );
    this.laddaSubmit = Ladda.create(button!);
  }

  created() {
    this.updateShopList();
    this.updateFeedList();
    this.populateFormFromShopContent();
  }

  populateFormFromShopContent() {
    this.form = {
      path: (this.shopContent && this.shopContent.path) ? this.shopContent.path : '',
      shop: (this.shopContent && this.shopContent.shop) ? this.shopContent.shop : null,
      feeds: (this.shopContent && this.shopContent.feeds) ? this.shopContent.feeds : null
    }
  }

  updateShopList() {
    // We need to get the list of available shops for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.postAPI('/api/admin/shop/shops/list', {}, options).then((response:any) => {
      if(response.shops) {  
        this.listShops = response.shops;
      }
    });
  }

  updateFeedList() {
    // We need to get the list of available shops for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.postAPI('/api/admin/blog/feeds/list', {}, options).then((response:any) => {
      if(response.feeds) {  
        this.listFeeds = response.feeds;
      }
    });
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var configuration = null;
    try {
      if(this.form.configuration != "") {
        configuration = JSON.parse(this.form.configuration); 
      }
    }
    catch(error:any) {
      configuration = undefined;
    }

    var input = {
      "shopID" : this.form.shop ? this.form.shop._id : null,
      "path" : this.form.path,
      "feedIDs" : this.form.feeds ? this.form.feeds.map((feed:any) => feed._id) : []
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();

    var path = '/api/admin/vigneron-online/shop-contents/create';
    if(this.shopContent) {
      path = '/api/admin/vigneron-online/shop-content/'+this.shopContent._id+'/update';
    }
    
    api.postAPI(path, input, options).then((response:any) => {
      if(response.created) {  
        this.$emit('shop-content-created', response.shopContent);
      }
      if(response.updated) {
        this.$emit('shop-content-updated', response.shopContent);
      }
      this.laddaSubmit!.stop();
    });
  }

  @Watch('site', { deep: true })
  onSiteChange(val: any, oldVal: any) {
    this.populateFormFromShopContent();
  }

  
  
}
</script>